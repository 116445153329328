import type { RouteLocationPathRaw } from 'vue-router'
import { useRouter } from 'vue-router'
import cloneDeep from 'clone-deep'
import { getUrlWithTrailingSlashes } from '@/utils/url'

export function useNavigate () {
  const router = useRouter()

  function getUrl (path: string, endWithSlash = true) {
    return getUrlWithTrailingSlashes(path, endWithSlash)
  }

  async function navigatePush (to: string | RouteLocationPathRaw, endWithSlash = true) {
    let cloned = cloneDeep(to)
    if (typeof cloned === 'string') {
      cloned = getUrl(cloned, endWithSlash)
    } else if (cloned.path) {
      cloned.path = getUrl(cloned.path, endWithSlash)
    }

    await router.push(cloned)
  }

  async function blankPush (to: string | RouteLocationPathRaw, event: MouseEvent, endWithSlash = true) {
    let cloned = cloneDeep(to)

    if (typeof cloned === 'string') {
      cloned = getUrl(cloned, endWithSlash)
    } else if (cloned.path) {
      cloned.path = getUrl(cloned.path, endWithSlash)
    }
    if (event.ctrlKey || event.metaKey) { // Проверка нажатия клавиши Ctrl или Command
      const url = typeof cloned === 'string' ? cloned : cloned.path
      window.open(url, '_blank')
      return
    }

    await router.push(cloned)
  }

  function navigateBack () {
    return router.back()
  }

  return {
    getUrl,
    navigatePush,
    blankPush,
    navigateBack
  }
}
