<template>
  <div
    v-if="breadcrumbs?.length && !loading"
    class="breadcrumbs"
    itemscope
    itemtype="https://schema.org/BreadcrumbList"
  >
    <div
      v-for="(crumb, idx) in breadcrumbs"
      :key="idx"
      itemprop="itemListElement"
      itemscope
      itemtype="https://schema.org/ListItem"
    >
      <component
        :is="crumb.link === undefined ? 'p' : NuxtLink"
        :to="origin + getUrl(crumb.link ?? '', !crumb.link?.includes('.html'))"
        :class="{ 'i-chevron-small-right-grey': idx !== 0 }"
        no-prefetch
        itemprop="item"
      >
        <span itemprop="name">{{ crumb.title }}</span>
      </component>
      <meta itemprop="position" :content="(idx+1).toString()">
    </div>
  </div>
  <div v-else-if="loading" class="breadcrumbs__skeleton" />
</template>

<script setup lang="ts">
import { defineComponent } from 'vue'
import { useNavigate } from '@/composables/useNavigate'

import type { PropType } from 'vue'

defineComponent({ name: 'ABreadcrumbs' })

const { origin } = useRequestURL()

const NuxtLink = resolveComponent('NuxtLink')

defineProps({
  breadcrumbs: {
    type: Array as PropType<{ link?: string, title: string }[]>,
    default: () => []
  },
  loading: Boolean
})

const { getUrl } = useNavigate()
</script>

<style lang="postcss">
.breadcrumbs {
  display: flex;
  overflow: auto;
  height: 1.5rem;
  scrollbar-width: none;

  & > div > a,
  & > div > p {
    display: flex;
    align-items: center;
    height: 100%;
    color: var(--color-text-middle);
    white-space: nowrap;

    @mixin text-sm;
  }

  & > div > a {
    @media (hover: hover) and (--screen-lg) {
      &:hover {
        color: var(--color-blue-800);
        cursor: pointer;
      }
    }
  }

  &__skeleton {
    width: 10rem;
    height: 100%;
    border-radius: var(--border-radius-xs);

    @mixin skeleton;
  }

  @media (--screen-xs) {
    user-select: none;
  }
}
</style>
