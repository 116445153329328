import type { Product, ProductDetails } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'
import type { Characteristic, ProductName, Property } from '@/modules/nuxt-api/models/Product'
import { getConfigJSON } from '@/utils/product-config'

export const sizes = {
  card: {
    mobile: { w: '288', h: '288' },
    desktop: { w: '280', h: '336' }
  },
  carousel: {
    mobile: { w: '136', h: '152' },
    desktop: { w: '290', h: '240' }
  },
  preview: {
    sm: { w: '50', h: '50' },
    base: { w: '50', h: '50' },
    lg: { w: '112', h: '112' }
  },
  reviewHomepage: {
    mobile: { w: '56', h: '180' },
    desktop: { w: '80', h: '212' }
  },
  headerTrigger: { w: '68', h: '68' },
  page: { w: '991', h: '600' },
  basket: {
    mobile: { w: '112', h: '200' },
    desktop: { w: '100', h: '160' }
  }
}

export type CharacteristicFormat = {
  code?: string
  name: string
  title: string
  path?: string
  isClosed?: boolean
}

type VolumeFormat = {
  value: string
  isDefault: false
}

export type NameFormat = {
  prefix?: string
  name?: string
  volume?: VolumeFormat[]
}

export type CharacteristicsItem = [string, Characteristic]
export type SnippetView = 'list' | 'grid' | 'big'
export type ProductView = 'list' | 'grid' | 'page'

type ProductManager = {
  getProductRegion: (product?: Product.AsObject) => CharacteristicFormat[]
  formatData: (viewType: ProductView, category: string, product: CharacteristicsItem[]) => Record<string, CharacteristicFormat[]>
  formatName: (
    productName: ProductName | undefined,
    volumeHighlight?: boolean,
    typeName?: string,
    metadataHeading?: string,
    metadataAnchor?: string,
  ) => NameFormat
  getBrandOrManufacturer: (product?: Product.AsObject, details?: ProductDetails.AsObject) => Property | undefined
}

export const useProductManager = (): ProductManager => {
  const getProductRegion = (product?: Product.AsObject): CharacteristicFormat[] => {
    if (!product) { return [] }

    const regionItem = product?.characteristicsMap
      ?.filter(item => item?.[1]?.code === 'region')?.[0]

    return product?.category === 'wine-set'
      ? []
      : regionItem?.[1]?.propertiesList
        ?.map(element => ({
          code: element.code,
          name: element.value,
          path: element.section?.path?.value,
          isClosed: element.section?.isClosed,
          title: regionItem[1].name
        })) ?? []
  }

  const formatData = (viewStatus: ProductView, category: string, product: CharacteristicsItem[]): Record<string, CharacteristicFormat[]> => {
    // calculate category
    const configCategory = category === 'champagnes-and-sparkles' ? 'champagnes' : category

    // import corresponding config
    const configJSON = getConfigJSON(configCategory)

    // format product
    const formattedProduct: Record<string, CharacteristicFormat[]> = {}

    if (configJSON) {
      const characteristicsArray = configJSON?.[viewStatus]?.accent ?? []
      let combinedData = characteristicsArray.flat()

      combinedData = combinedData.map((item: any) => {
        return Object.keys(item)
      })

      combinedData?.forEach((itemData: any) => {
        product?.forEach((item: CharacteristicsItem) => {
          if (item[0] === itemData[0]) {
            formattedProduct[itemData] = []

            item?.[1]?.propertiesList?.forEach((itemNew: any) => {
              const valueUnit = itemNew.valueUnit !== undefined && itemNew.valueUnit !== '' ? ' ' + itemNew.valueUnit : ''
              formattedProduct[itemData].push({

                name: itemNew.value + valueUnit,
                title: item?.[1].name,
                path: itemNew.section?.path?.value,
                isClosed: itemNew.section?.isClosed
              })
            })
          }
        })
      })
    }

    return formattedProduct
  }

  const formatName = (
    name: ProductName | undefined,
    volumeHighlight = false,
    typeName?: string,
    metadataHeading?: string,
    metadataAnchor?: string
  ): NameFormat => {
    if (metadataHeading?.length) { return { name: metadataHeading } }
    if (!name) { return {} }

    const prefix = metadataAnchor?.length && !name.value.toLowerCase().includes(metadataAnchor.toLowerCase())
      ? `${metadataAnchor} `
      : typeName?.length
        ? `${typeName} `
        : ''

    const volume = (name.characteristicPartsList ?? []).map((item, idx, arr) => ({
      isDefault: item.isDefault,
      value: `${item.value.replace(' ', '\xA0')}${idx === arr.length - 1 ? '' : ', '}`
    })) as VolumeFormat[]

    return {
      prefix,
      name: `${name.value}${volume.length ? `, ${volumeHighlight ? '' : volume.map(item => item.value).join('')}` : ''}`,
      volume: volumeHighlight ? volume : []
    }
  }

  const getBrandOrManufacturer = (product?: Product.AsObject, details?: ProductDetails.AsObject): Property | undefined => {
    const brandFullInfo = details?.characteristicsMap?.filter(
      item => item?.[0] === 'brand'
    )

    if (brandFullInfo?.length !== 0) {
      return brandFullInfo?.[0]?.[1]?.propertiesList?.[0]
    }

    const manufacturerFullInfo = product?.characteristicsMap?.filter(
      item => item?.[0] === 'manufacturer'
    )

    if (manufacturerFullInfo?.length !== 0) {
      return manufacturerFullInfo?.[0]?.[1]?.propertiesList?.[0]
    }
  }

  return { getProductRegion, formatData, formatName, getBrandOrManufacturer }
}
